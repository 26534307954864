import {useState} from "react";
import {Input} from "../models";

export function useInputData(initialInput?: Input) {
    const [input, setInput] = useState<Input | undefined>(initialInput);
    const encode = (input: Input): string => {
        return btoa(JSON.stringify(input));
    }

    const decode = (token: string): Input => {
        let inputData = JSON.parse(atob(token));
        setInput(inputData);
        return inputData;
    }

    return {input, setInput, encode, decode};
}