import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {QueryClient, QueryClientProvider} from 'react-query';
import './theme.scss';
import {BrowserRouter} from "react-router-dom";

const client = new QueryClient();

ReactDOM.render(
    <QueryClientProvider client={client}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </QueryClientProvider>,
    document.getElementById('root')
);
