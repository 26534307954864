import React from "react";
import {Col, Image, Row} from "react-bootstrap";
import BackgroundImage from "./resources/images/AWS_Pattern_Blueprint-Blocks-A_Light-BG_Blue.png";

function OnBoarding() {
    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className="bg-onboarding-header d-flex">
                        <div className="">
                            <h1 className="display-5">Amazon FSx for NetApp ONTAP Pricing Calculator</h1>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col xs={6}>
                    <p className="lead">Calculate your total cost of ownership (TCO) of storing your data on Amazon FSx for NetApp ONTAP for your Linux, Windows, and macOS applications.</p>
                </Col>
                <Col xs={6}>
                    <Image className="img-fluid" src={BackgroundImage} alt=""/>
                </Col>
                <Col xs={12}>
                    <p>
                        Amazon FSx for NetApp ONTAP is a storage service that allows you to launch and run fully managed
                        NetApp ONTAP file systems in the AWS Cloud. It provides the familiar features, performance,
                        capabilities, and APIs of NetApp file systems with the agility, scalability, and simplicity of
                        a fully managed AWS service.
                    </p>
                    <p>
                        Amazon FSx for NetApp ONTAP supports two features that enable you to reduce your storage costs:
                        elastic capacity pool tiering and compression/deduplication.
                    </p>
                    <p>
                        Each Amazon FSx for NetApp ONTAP file system has two storage tiers: primary storage and capacity
                        pool storage. Primary storage is provisioned, high-performance SSD storage that’s purpose-built
                        for the active portion of your data set. Capacity pool storage is a fully elastic storage tier
                        that can scale to petabytes in size and is cost-optimized for infrequently-accessed data.
                        Amazon FSx for NetApp ONTAP automatically tiers data from SSD storage to capacity pool storage
                        based on your access patterns, allowing you to achieve SSD levels of performance for your
                        workload while only paying for SSD storage for a small fraction of your data.
                    </p>
                    <p>
                        Amazon FSx for NetApp ONTAP also supports all of ONTAP’s storage efficiency and cost-savings
                        features, including compression and deduplication. Together, these features automatically reduce
                        the storage consumption on your file system storage and your file system backups, typically
                        a 65% savings for general-purpose workloads.
                    </p>
                </Col>
            </Row>
        </>
    )
}

export default OnBoarding;