import {CurrencyUnit, FsxResponse, Input, Pricing} from "../models";
import {
    Button,
    Card,
    Collapse,
    FormControl,
    InputGroup,
    ListGroup,
    OverlayTrigger,
    Popover,
    Toast
} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {COLORS} from "./Constants";
import {
    CategoryScale,
    Chart,
    ChartConfiguration,
    ChartItem,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip
} from "chart.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCopy, faQuestionCircle, faShare} from "@fortawesome/free-solid-svg-icons";

import './PricingTable.scss';

function PricingTable(props: { input: Input, fsxResponse: FsxResponse }) {
    // eslint-disable-next-line
    const [openBreakdown, setOpenBreakdown] = useState(true);
    const chartRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (chartRef === null) {
            return;
        }

        const cumulativeSpendData = props.fsxResponse.graphs.find(g => g.id === 'cumulativeSpend');

        const cumulativeSpendChart = cumulativeSpendData ? {
            type: 'line',
            data: {
                labels: cumulativeSpendData.data.map((item, index) => index + 1),
                datasets: [{
                    label: 'Amazon FSx for NetApp ONTAP',
                    data: cumulativeSpendData.data.map((item) => Math.round(item.fsxGa)),
                    borderColor: COLORS.orange,
                }, {
                    label: 'On-premises',
                    data: cumulativeSpendData.data.map((item) => Math.round(item.onPrem)),
                    borderColor: COLORS.blue,
                }]
            },
            options: {
                plugins: {
                    tooltip: {
                        callbacks: {
                            title(tooltipItems): string | string[] {
                                return `Month #${tooltipItems[0].label}`;
                            },
                            label: function (context) {
                                return `${context.dataset.label}: US$${context.parsed.y.toLocaleString()}`;
                            }
                        }
                    }
                },
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
                scales: {
                    x: {
                        ticks: {
                            callback: function (value, index, values) {
                                return index % 10 === 0 ? `Month #${value}` : '';
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, values) {
                                return `US$${value.toLocaleString()}`;
                            }
                        }
                    }
                }
            }
        } as ChartConfiguration<'line'> : undefined;

        if (cumulativeSpendChart) {
            const myChart = new Chart(chartRef.current as ChartItem, cumulativeSpendChart);

            return () => {
                myChart.destroy();
            }
        }
    }, [chartRef, props.fsxResponse]);

    Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip);


    const href = window.location.href;
    const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

    function copyToClipboard() {
        setCopiedToClipboard(true);
        return navigator.clipboard.writeText(window.location.href);
    }

    function convertCurrency(currencyUnit: CurrencyUnit) {
        if (currencyUnit === 'USD') {
            return '$';
        }
    }

    const popoverShareLink = (
        <Popover id="popover-basic">
            <Popover.Content className="mb-2">
                <p className="text-muted">Share the results by copy the URL and giving it to the person(s) of your
                    choosing.</p>
                <InputGroup>
                    <InputGroup.Prepend>
                        <Button variant="primary"
                                onClick={() => copyToClipboard()}>
                            <FontAwesomeIcon icon={faCopy}/>
                        </Button>
                    </InputGroup.Prepend>
                    <FormControl defaultValue={href} readOnly/>
                </InputGroup>
                <Toast show={copiedToClipboard}
                       onClose={() => setCopiedToClipboard(false)}
                       className="bg-white border-0 shadow-none p-0"
                       delay={3000}
                       autohide>
                    <Toast.Body className="p-0">
                        <small className="text-success">
                            <FontAwesomeIcon icon={faCheck} className="mr-1"/>
                            Copied to clipboard
                        </small>
                    </Toast.Body>
                </Toast>
            </Popover.Content>
        </Popover>
    );

    const awsCost = props.fsxResponse.pricing.find(p => p.id === 'awsFsx');
    const backupsCost = awsCost
        ? awsCost.sections
            .filter(p => p.id === 'backups')
            .reduce((acc, s) => acc + s.cost, 0)
        : 0;
    // Commented out on September 8th, 2021: To deliver a simplified version of the calculator
    // const onPremCost = props.fsxResponse.pricing.find(p => p.id === 'onPrem');
    // let totalSavings = 0;
    // let totalSavingPercentage = 0;
    // if (awsCost && onPremCost) {
    //     totalSavings = Math.round((onPremCost.cost - awsCost.cost));
    //     totalSavingPercentage = Math.round(100 * (1 - (awsCost.cost / onPremCost.cost)));
    // }
    // eslint-disable-next-line
    const popoverAWSTotalCost = TooltipContent("AWS costs assume a multi-availability zone configuration where " +
        "AWS synchronously replicates data across availability zones for high availability and durability.");
    // eslint-disable-next-line
    const popoverOnPremTotalCost = TooltipContent(
        "On-premises costs assume a 40% reduction of IDC costs " +
        "on average discounts, and a second data center for high availability and durability.",
        "Source: IDC Pricing Evaluator Service: Storage Comparison Tool v5.7.257"
    );

    function TooltipContent(content: string, boldContent?: string) {
        return (
            <Popover id="popover-basic">
                <Popover.Content>
                    {content} <strong>{boldContent}</strong>
                </Popover.Content>
            </Popover>
        );
    }

    function renderPricing(pricing: Pricing) {
        let help;
        // Commented out on September 8th, 2021: To deliver a simplified version of the calculator
        // if (pricing.id === 'awsFsx') {
        //     help = popoverAWSTotalCost;
        // }
        // if (pricing.id === 'onPrem') {
        //     help = popoverOnPremTotalCost;
        // }

        return (
            <>
                <p className="font-weight-light text-nowrap mb-0">Total cost for {pricing.label}
                    {help && (
                        <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={help}>
                            <FontAwesomeIcon className="tooltip-icon ml-1" icon={faQuestionCircle}/>
                        </OverlayTrigger>
                    )}
                </p>
                <p className="text-secondary">
                    {props && props.input && props.input.storage && !props.input.storage.multiAz ?
                        (
                            <small>
                                Amazon FSx for NetApp ONTAP file systems automatically replicate your data within an AWS
                                Availability Zone (AZ).
                                The prices below are based on a Single-AZ Amazon FSx for NetApp ONTAP deployment.
                            </small>
                        ) : (
                            <small>
                                Amazon FSx for NetApp ONTAP file systems automatically replicate your data across
                                multiple AWS
                                Availability Zones (AZs) for high availability and durability. The prices below are
                                based on a
                                Multi-AZ
                                Amazon FSx for NetApp ONTAP deployment.
                            </small>
                        )
                    }

                </p>
                {/*Commented out on September 8th, 2021: To deliver a simplified version of the calculator*/}
                {/*<div className="text-nowrap mb-3">*/}
                {/*    <sup style={{*/}
                {/*        fontSize: 20,*/}
                {/*        fontWeight: 400*/}
                {/*    }}>{convertCurrency(pricing.currency)}</sup>*/}
                {/*    <span style={{fontSize: 40, fontWeight: 300}}>*/}
                {/*        {Math.round(pricing.cost).toLocaleString()}*/}
                {/*    </span>*/}
                {/*    <small style={{fontSize: 13, fontWeight: 500}}>/{pricing.unit}</small>*/}
                {/*    <span className="text-secondary border-left ml-2 pl-2">*/}
                {/*        <sup style={{*/}
                {/*            fontSize: 10,*/}
                {/*            fontWeight: 400*/}
                {/*        }}>{convertCurrency(pricing.currency)}</sup>*/}
                {/*        <span style={{fontSize: 20, fontWeight: 300}}>*/}
                {/*            {(pricing.cost / (props.input.storage.data * 1024)).toLocaleString(undefined, {})}*/}
                {/*        </span>*/}
                {/*        <small style={{fontSize: 10, fontWeight: 500}}> per GiB-month</small>*/}
                {/*    </span>*/}
                {/*</div>*/}

                {pricing.sections.map((pricingSection, pricingIndex) => (
                    <div className={`d-flex flex-row p-2 pt-3 pb-3 ${pricingIndex > 0 ? 'border-top' : ''}`}
                         key={pricingSection.id}>
                        <div className="flex-shrink-0 w-150 price">
                            <sup className="price-currency">{convertCurrency(pricingSection.currency)}</sup>
                            <span className="price-cost">
                                {Math.round(pricingSection.cost).toLocaleString()}
                            </span>
                            <small className="price-unit">/{pricingSection.unit}</small>
                        </div>
                        <div>
                            <h6>{pricingSection.label}</h6>
                            {pricingSection.breakdown.map((breakdown, breakdownIndex) => (
                                <div key={breakdownIndex}>
                                    <small className="text-muted font-italic d-inline d-block">
                                        {breakdown.label}: {convertCurrency(breakdown.currency)}{breakdown.cost.toLocaleString()} {breakdown.currency ? 'per ' : ''}{breakdown.unit}
                                    </small>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </>
        );
    }

    function renderSaving(cost: number, unit: string, currency: CurrencyUnit, isBackup = false) {
        return (
            <span className={`saving ${isBackup ? 'with-backups' : 'without-backups'}`}>
                <sup className="saving-currency">{convertCurrency(currency)}</sup>
                <span className="saving-cost">
                    {Math.round(cost).toLocaleString()}
                </span>
                <small className="saving-unit">/ {unit}</small>
                <span className="border-left border-light ml-2 pl-2">
                    <sup className="saving-currency-unit">{convertCurrency(currency)}</sup>
                    <span className="saving-cost-unit">
                        {(cost / (props.input.storage.data * 1024)).toLocaleString(undefined, {})}
                    </span>
                    <small className="saving-unit-unit"> per GiB-month</small>
                </span>
            </span>
        )
    }

    return (
        <>
            <Card bg={"dark"} text={"white"} className="shadow">
                <Card.Body>
                    <Card.Text className="d-flex flex-wrap justify-content-end align-items-center">
                        <span className="font-weight-lighter text-uppercase flex-grow-1">Total monthly cost</span>
                        <OverlayTrigger
                            trigger={["click"]}
                            placement="left"
                            overlay={popoverShareLink}
                            rootClose>
                            <Button variant={"link"} className="text-white mr-3"><FontAwesomeIcon icon={faShare}/> Share</Button>
                        </OverlayTrigger>
                        <Button variant={"warning"} href="https://pages.awscloud.com/fsxontap.html">Ask an AWS
                            expert</Button>
                    </Card.Text>
                    <div className={`row mx-lg-n3 ${backupsCost > 0 ? 'mt-5' : ''}`}>
                        {awsCost && backupsCost > 0 && (
                            <Card.Text className="px-lg-3">
                                <span
                                    className="d-block font-weight-lighter text-uppercase m-0">Excluding backups</span>
                                <span className="d-inline-block text-nowrap">
                                {renderSaving(awsCost.cost - backupsCost, awsCost.unit, awsCost.currency, backupsCost > 0)}
                            </span>
                            </Card.Text>
                        )}
                        {awsCost && (
                            <Card.Text className="px-lg-3">
                                {backupsCost > 0 && (
                                    <span
                                        className="d-block font-weight-lighter text-uppercase m-0">Including backups</span>
                                )}
                                <span className="d-inline-block text-nowrap">
                                    {awsCost && renderSaving(awsCost.cost, awsCost.unit, awsCost.currency, backupsCost > 0)}
                                </span>
                                {/*Commented out on September 8th, 2021: To deliver a simplified version of the calculator*/}
                                {/*<Badge pill variant={totalSavingPercentage >= 0 ? "success" : "danger"} className="ml-2">*/}
                                {/*    {totalSavingPercentage.toLocaleString()}%*/}
                                {/*</Badge>*/}
                                {/*<Button variant={"link"} className="text-secondary"*/}
                                {/*        onClick={() => setOpenBreakdown(!openBreakdown)}>*/}
                                {/*    {openBreakdown ? 'Hide' : 'See'} breakdown*/}
                                {/*</Button>*/}
                            </Card.Text>
                        )}
                    </div>
                </Card.Body>
            </Card>

            <Collapse in={openBreakdown} className="mt-3">
                <ListGroup className="shadow">
                    {/*Commented out on September 8th, 2021: To deliver a simplified version of the calculator*/}
                    {/*<ListGroup.Item variant={"info"}>*/}
                    {/*    <small className="text-muted font-italic">*/}
                    {/*        There is a potential total {totalSavingPercentage > 0 ? "saving" : "additional cost"} of <Badge*/}
                    {/*            variant={totalSavingPercentage >= 0 ? "success" : "danger"} pill>*/}
                    {/*            {Math.abs(totalSavingPercentage).toLocaleString()}%*/}
                    {/*        </Badge> by provisioning FSx Windows storage on AWS. For more information on the breakdown by item, see the table below.*/}
                    {/*    </small>*/}
                    {/*</ListGroup.Item>*/}

                    <ListGroup.Item>
                        <div className="d-flex flex-lg-nowrap flex-wrap justify-content-between">
                            {awsCost && (
                                <div key={awsCost.id} className={`flex-grow-1 p-3`}>
                                    {renderPricing(awsCost)}
                                </div>
                            )}
                        </div>

                    </ListGroup.Item>
                </ListGroup>
            </Collapse>

            {/*Commented out on September 8th, 2021: To deliver a simplified version of the calculator*/}
            {/*<Card className="mt-3 shadow">*/}
            {/*    <Card.Body>*/}
            {/*        <Card.Title className="mb-5">Cumulative spend over time ($)</Card.Title>*/}
            {/*        <canvas ref={chartRef} width="400" height="300"/>*/}
            {/*    </Card.Body>*/}
            {/*</Card>*/}
        </>
    );
}

export default PricingTable;