import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {LambdaError} from "../models";
import {Col, Row} from "react-bootstrap";

function LoadingScreen(props: { error: LambdaError | undefined }) {

    return (
        <>
            <Row className="mb-3">
                <Col sm={{span: 8, offset: 2}} md={6}>
                    {!props.error && (
                        <div>
                            <p className="lead">Calculation in progress <FontAwesomeIcon spin icon={faSpinner}/>
                            </p>
                        </div>
                    )}

                    {props.error && (
                        <div className="lead">Calculation error
                            <p className="text-danger"><strong>{props.error.code}: </strong>{props.error.message}</p>
                        </div>
                    )}
                </Col>
            </Row>

        </>
    );
}

export default LoadingScreen;