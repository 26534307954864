export interface IAppConfig {
  Stage: string
  APIDomain: string
}

export const stages = ["local", "beta", "gamma", "prod", undefined]
type stagetypes = typeof stages[number]

class DynamicAppConfig {
  public readonly StageConfigOverrides = [
    {
      Stage: "local",
      APIDomain: "https://api.alpha-fsx.tool.migration-evaluator.aws.dev/alpha"
    },
    {
      Stage: "alpha",
      APIDomain: "https://api.alpha-fsx.tool.migration-evaluator.aws.dev/alpha"
    },
    {
      Stage: "beta",
      APIDomain: "https://api.beta-fsx.tool.migration-evaluator.aws.dev/beta"
    },
    {
      Stage: "prod",
      APIDomain: "https://api.fsxontap.calculator.aws/prod"
    }
  ]

  private domain: string | undefined = undefined

  private domainMap: Map<string, stagetypes> = new Map([
    ["alpha-fsx.tool.migration-evaluator.aws.dev", "alpha"],
    ["beta-fsx.tool.migration-evaluator.aws.dev", "beta"],
    ["fsxontap.calculator.aws", "prod"],
  ])

  setDomain = (currentDomain: string) => {
    this.domain = currentDomain
  }

  private getStageFromDomain(): stagetypes {
    if (this.domain === undefined) {
      this.setDomain(window.location.hostname)
    }
    if (this.domain === undefined) return undefined
    return this.domainMap.get(this.domain)
  }

  private getStageFromEnv(): string | undefined {
    return this.StageConfigOverrides.findIndex(
      config => config.Stage === process.env.REACT_APP_STAGE
    ) > 0
      ? process.env.REACT_APP_STAGE
      : undefined
  }

  get Config(): IAppConfig {
    let stage = this.getStageFromEnv()
    if (stage === undefined) stage = this.getStageFromDomain()
    if (stage === undefined) stage = "local"

    const stageConfig = this.StageConfigOverrides.find(
      config => config.Stage === stage
    )

    return stageConfig as IAppConfig
  }
}

export default new DynamicAppConfig()
