export const COLORS = {
    orange: '#FF9900',
    blue: '#527FFF',
    purple: '#A166FF',
    // your colors
}

export const SINGLE_AZ = "Single-AZ"
export const SINGLE_AZ_2 = "Single-AZ-2"
export const MULTI_AZ = "Multi-AZ"
export const MULTI_AZ_2 = "Multi-AZ-2"